import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../Layouts/index";
// import cover from '../../images/history.png';
import Newsletter from "../../components/Newsletter";

class History extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-history common">
        <SEO title="Our history" />
        <div className="cover-container">
          <div className="cover-img">
            <Img
              fluid={this.props.data.cover.childImageSharp.fluid}
              className="img-fluid"
              alt="Cover Image"
            />
          </div>
          <div className="cover-content">
            <div className="wrap-hero">
              <h1 className="hero-title">Our History</h1>
              {/*<h4 className="hero-sub-title">Quality that's stood the test of time</h4>*/}
            </div>
          </div>
        </div>

        <div className="para-one text-center">
          <div className="wrapper-bel">
            {" "}
            <h3 className="title-bel">
              {" "}
              Identifying a breed of such amazing quality and consistency
              created a clear vision for SPKL....
            </h3>
          </div>
          <div className="title-2 color-primary text-uppercase">
            <div className="bar-wrapper">
              <div className="bar" />
            </div>
            <div className="wrapper-m">
              {" "}
              <h2 className="title-2">
                {" "}
                to be Australia's most loved <br/> premium beef brand{" "}
              </h2>
            </div>
            <div className="bar-wrapper">
              {" "}
              <div className="bar2" />
            </div>
          </div>
        </div>

        <div className="wrapper-pr">
          <div className="para-two text-center color-white ">
            After a ten year legacy of heavy investment, relationship building
            and promotion of the Speckle Park breed in Australia, award winning
            Speckle Park Breeders Dale Humphries (Wattle Grove Speckle Park) and
            Greg Ebbeck (Six Star Speckle Park) saw an opportunity to create a
            new and united category of beef in Australia, benefiting producers
            across Australia whilst servicing Australia’s top chefs and
            restaurants with steak on plates.
          </div>

          <div className="para-three">
            The Speckle Park Group was subsequently formed in 2018 and with the
            backing of an experienced group of board members, SPKL was launched
            to the global marketplace.
          </div>
        </div>
        <Newsletter />
      </Layout>
    );
  }
}

export default History;

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "history.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
